import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { EventContext } from '../../../context/EventContextProvider'
import { UserContext } from '../../../context/UserAndTokenContextProvider'
import { openModal, updateTokens } from '../../../redux/actions/actionBuilders'
import BillySDK from '../../../sdk/sdk'
import ModalType from '../../../structures/Enums/ModalType.ts'
import { isNotNullOrUndefined } from '../../../structures/Guards/guards.utils.ts'
import Button from '../../01_atoms/Button/Button.tsx'
import PhoneField from '../../forms/payment/phone/PhoneField'
import PromotionProductInput from '../PromotionProductInput/PromotionProductInput.tsx'

import './promotionregistrationfrom.scss'

function PromotionRegistrationForm({
    id,
    color,
    promoProducts,
    initialPromoProductValue,
    onSuccessfulGenerate,
    multiSelect = false,
    multiMax = 1,
}) {
    const { t } = useTranslation()
    const { user } = useContext(UserContext)
    const { event } = useContext(EventContext)
    const dispatch = useDispatch()

    // PRODUCTS SELECT
    // TODO: Reuse PromotionProductSelectForm Component

    const defaultProductValues = useMemo(() => {
        if (isNotNullOrUndefined(initialPromoProductValue)) {
            return [{ id: initialPromoProductValue, amount: 1 }]
        }
        if (
            promoProducts.length === 1 &&
            isNotNullOrUndefined(promoProducts) &&
            isNotNullOrUndefined(promoProducts[0]?.id)
        ) {
            return [{ id: promoProducts[0].id, amount: 1 }]
        }
        return []
    }, [initialPromoProductValue, promoProducts])

    const [productValues, setProductValues] = useState(defaultProductValues)

    const [amountReached, setAmountReached] = useState(false)

    const totalSelectedAmounts = useMemo(
        () => productValues.reduce((totalAmount, value) => (totalAmount += value.amount), 0),
        [productValues]
    )

    const guestLogin = useMutation({
        retry: 2,
        refetchOnWindowFocus: false,
        mutationFn: () => BillySDK.guestLogin(),
    })

    const userUpdate = useMutation({
        retry: 2,
        refetchOnWindowFocus: false,
        mutationFn: (userUpdate) => BillySDK.updateUser(userUpdate),
    })

    const generateOtp = useMutation({
        retry: 2,
        refetchOnWindowFocus: false,
        mutationFn: (phone) => BillySDK.promotionsOtpGenerate(phone),
    })

    const onError = useCallback((error, formActions) => {
        dispatch(
            openModal({
                type: ModalType.ERROR_MODAL,
                data: {
                    message: error?.message,
                },
            })
        )
        formActions.setSubmitting(false)
    }, [])

    const onUserSuccessfullyHandled = useCallback(
        (values, formActions) => {
            generateOtp.mutate(
                { phone_number: values.phone?.replace(/\D+/g, '') },
                {
                    onSuccess: () => onSuccessfulGenerate?.(productValues),
                    onError: (error) => onError(error, formActions),
                }
            )
        },
        [generateOtp, onSuccessfulGenerate, onError]
    )

    const onHandleSubmit = useCallback(
        (values, formActions) => {
            if (typeof user === 'undefined') {
                guestLogin.mutate(null, {
                    onSuccess: (response) => {
                        dispatch(
                            updateTokens({
                                access_token: response.token,
                            })
                        )
                        onUserSuccessfullyHandled(values, formActions)
                    },
                    onError: (error) => onError(error, formActions),
                })
            } else {
                userUpdate.mutate(
                    { phone: values.phone?.replace(/\D+/g, '') },
                    {
                        onSuccess: () => onUserSuccessfullyHandled(values, formActions),
                        onError: (error) => onError(error, formActions),
                    }
                )
            }
        },
        [onUserSuccessfullyHandled, onError, user]
    )

    // PRODUCTS SELECT
    // TODO: Reuse PromotionProductSelectForm Component

    const handleChange = useCallback((productId) => {
        setProductValues((previousState) => {
            if (!multiSelect) {
                previousState = [{ id: productId, amount: 1 }]
            } else {
                const optionIndex = previousState.findIndex((value) => value.id === productId)
                if (optionIndex === -1 && !amountReached) {
                    previousState.push({ id: productId, amount: 1 })
                } else {
                    previousState = previousState.filter((value) => value.id !== productId)
                }
            }

            return [...previousState]
        })
    }, [])

    const handleAmountChange = useCallback((valueId, amount) => {
        setProductValues((previousState) => {
            const update = previousState.find((value) => value.id === valueId)
            if (isNotNullOrUndefined(update)) {
                update.amount = amount
            }

            return [...previousState]
        })
    }, [])

    useEffect(() => {
        setAmountReached(totalSelectedAmounts >= multiMax)
    }, [totalSelectedAmounts, multiMax])

    return (
        <div className='promotion-registration-form otp-form'>
            <div className='c-promo-product-select-container'>
                <div className='c-promo-products'>
                    {promoProducts.map((promoProduct) => (
                        <PromotionProductInput
                            key={promoProduct.id}
                            value={{ name: promoProduct.name, id: promoProduct.id }}
                            isMultiSelect={multiSelect}
                            promotionId={id ?? 1}
                            multiMax={multiMax}
                            maxAmountReached={amountReached}
                            onChange={handleChange}
                            onAmountChange={handleAmountChange}
                            amount={productValues.find((item) => item.id === promoProduct.id)?.amount ?? 1}
                            active={
                                isNotNullOrUndefined(productValues.find((item) => item.id === promoProduct.id)) ?? false
                            }
                        />
                    ))}
                </div>
            </div>
            <Formik
                initialValues={{
                    phone: user?.phone ? `+${user.phone}` : '',
                }}
                validationSchema={Yup.object().shape({
                    phone: Yup.string().required(t('validation.phone.required')),
                })}
                onSubmit={(values, actions) => {
                    onHandleSubmit(values, actions)
                }}>
                {({ status, isSubmitting }) => (
                    <Form>
                        <div>
                            <div>
                                <PhoneField
                                    label={t(
                                        'promotions.input.otp_phone.label',
                                        'Please enter your phone number to receive your personal activation code:'
                                    )}
                                    disabled={isSubmitting}
                                    defaultCountry={event?.venue?.country ?? 'BE'}
                                />
                            </div>

                            {status && status.loginstatus && (
                                <div>
                                    <p className='u-error u-text-center'>{status.loginstatus}</p>
                                </div>
                            )}

                            <div>
                                <Button
                                    className='submitbutton'
                                    type='submit'
                                    disabled={isSubmitting || productValues.length === 0 || !amountReached}
                                    variant='dark'
                                    style={isNotNullOrUndefined(color) ? { background: color } : {}}
                                    label={
                                        isSubmitting
                                            ? t('button.form.disabled.cta')
                                            : t('promotions.otp_phone.submit.cta', 'Request personal activation code')
                                    }
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default PromotionRegistrationForm
