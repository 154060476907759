import { getBrand } from '../structures/Enums/Brands.enum.ts'
import HeroType from '../structures/Enums/HeroType.enum.ts'

import { hexToRgb } from './helpers'

const root = document.documentElement

const setColorCssVariables = (variableName, variableValue) => {
    // in case the value has a pound
    variableValue = String(variableValue).replace('#', '')

    // check if color value is a valid color
    if (/^#[0-9A-F]{6}$/i.test(`#${variableValue}`)) {
        const colorParts = hexToRgb(`#${variableValue}`)

        if (colorParts !== null) {
            root.style.setProperty(`--b-partial-${variableName}-red`, String(colorParts?.r))
            root.style.setProperty(`--b-partial-${variableName}-green`, String(colorParts?.g))
            root.style.setProperty(`--b-partial-${variableName}-blue`, String(colorParts?.b))
            root.style.setProperty(
                `--b-variant-${variableName}-rgb`,
                `${String(colorParts?.r)} ${String(colorParts?.g)} ${String(colorParts?.b)}`
            )
            root.style.setProperty(
                `--b-variant-${variableName}-dark`,
                `${String(colorParts?.r - 15)} ${String(colorParts?.g - 15)} ${String(colorParts?.b - 15)}`
            )
            root.style.setProperty(
                `--b-variant-${variableName}-light`,
                `${String(colorParts?.r + 10)} ${String(colorParts?.g + 10)} ${String(colorParts?.b + 10)}`
            )
        }

        root.style.setProperty(`--b-${variableName}`, `#${variableValue}`)
    }
}

export const setRootCssVariable = (variableName, variableValue) => {
    // theme-properties we want to transform into a CSS variable
    const whiteList = ['color', 'backgroundColor', 'textColor', 'categoryTextColor']

    if (whiteList.includes(variableName)) {
        // create variants of all color properties
        if (['color', 'backgroundColor', 'textColor', 'categoryTextColor'].includes(variableName)) {
            setColorCssVariables(variableName, variableValue)
            return
        }

        // Create all other css variables
        root.style.setProperty(`--b-${variableName}`, variableValue)
    }
}

export const getDefaultTheme = () => {
    const brand = getBrand()

    return {
        color: brand.theme.color.primary,
        backgroundColor: brand.theme.color.background,
        textColor: 'FFFFFF',
        categoryTextColor: '353535',
        logo: brand.theme.logo.header,
        heroStyle: HeroType.HERO_TYPE_ORIGINAL,
        translations: null,
    }
}
