import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { Field, Form, Formik } from 'formik'
import { motion } from 'framer-motion'
import * as Yup from 'yup'

import { staggerFadeInUpChild, staggerFadeInUpContainer } from '../../constants/Animations.ts'
import { openModal, updateTokens } from '../../redux/actions/actionBuilders'
import BillySDK from '../../sdk/sdk'
import ModalType from '../../structures/Enums/ModalType.ts'
import { isNotNullOrUndefined } from '../../structures/Guards/guards.utils.ts'
import Button from '../01_atoms/Button/Button.tsx'

import Checkbox from './elements/Checkbox'
import CodeInput from './payment/code/CodeInput'

function PromoValidationForm({ color, onSuccessfulVerify = () => undefined }) {
    const { t } = useTranslation()
    const [isValidCode, setIsValidCode] = useState(false)
    const dispatch = useDispatch()

    const verifyOtp = useMutation({
        retry: 2,
        refetchOnWindowFocus: false,
        mutationFn: (code) => BillySDK.promotionsOtpVerify(code),
    })

    const onError = (error, formActions) => {
        dispatch(
            openModal({
                type: ModalType.ERROR_MODAL,
                data: {
                    message: error?.message,
                },
            })
        )
        formActions.setSubmitting(false)
    }

    const onHandleSubmit = useCallback((values, formActions) => {
        verifyOtp.mutate(values, {
            onSuccess: (response) => {
                if (response.token) {
                    dispatch(
                        updateTokens({
                            access_token: response.token,
                        })
                    )
                    onSuccessfulVerify()
                } else {
                    onError(undefined, formActions)
                }
            },
            onError: (error) => onError(error, formActions),
        })
    }, [])
    const onIsValidCode = useCallback((isValid) => {
        setIsValidCode(isValid)
    }, [])

    return (
        <Formik
            onSubmit={(values, actions) => onHandleSubmit(values, actions)}
            initialValues={{
                code: '',
                terms_and_conditions: false,
                news_and_promotions: false,
                personalized_ads: false,
            }}
            validationSchema={Yup.object().shape({
                code: Yup.string().required(t('validation.phone.required')),
                terms_and_conditions: Yup.bool().oneOf([true], t('validation.email.required')),
                news_and_promotions: Yup.bool().notRequired(),
                personalized_ads: Yup.bool().notRequired(),
            })}>
            {({ values, status, isSubmitting, handleChange, handleBlur }) => (
                <Form>
                    <motion.div
                        variants={staggerFadeInUpContainer}
                        initial='hidden'
                        animate='show'>
                        <motion.div variants={staggerFadeInUpChild}>
                            <Field
                                fields='4'
                                key='code'
                                component={CodeInput}
                                name='code'
                                id='code'
                                label={t('promotions.input.otp_code.label', 'Your personal activation code:')}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                onBlur={handleBlur}
                                onIsValidCode={onIsValidCode}
                                autoComplete='one-time-code'
                            />
                        </motion.div>

                        <motion.div variants={staggerFadeInUpChild}>
                            <Field
                                component={Checkbox}
                                id='terms_and_conditions'
                                name='terms_and_conditions'
                                type='checkbox'
                                label={t('promotions.legal.tada.terms_and_conditions.label')}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                onBlur={handleBlur}
                            />
                        </motion.div>

                        <motion.div variants={staggerFadeInUpChild}>
                            <Field
                                component={Checkbox}
                                id='news_and_promotions'
                                name='news_and_promotions'
                                type='checkbox'
                                label={t('promotions.legal.tada.news_and_promotions.label')}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                onBlur={handleBlur}
                            />
                        </motion.div>

                        <motion.div variants={staggerFadeInUpChild}>
                            <Field
                                component={Checkbox}
                                id='personalized_ads'
                                name='personalized_ads'
                                type='checkbox'
                                label={t('promotions.legal.tada.personalized_ads.label')}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                onBlur={handleBlur}
                            />
                        </motion.div>

                        <div style={{ marginTop: '10px' }}>
                            {status && status.loginstatus && (
                                <div>
                                    <p className='u-error u-text-center'>{status.loginstatus}</p>
                                </div>
                            )}

                            <motion.div
                                style={{ position: 'relative' }}
                                variants={staggerFadeInUpChild}>
                                <Button
                                    className='submitbutton'
                                    type='submit'
                                    disabled={
                                        isSubmitting || !(isValidCode === true && values.terms_and_conditions === true)
                                    }
                                    variant='dark'
                                    style={isNotNullOrUndefined(color) ? { background: color } : {}}
                                    label={
                                        isSubmitting
                                            ? t('button.form.disabled.cta')
                                            : t('promotions.add_to_basket.cta', 'Add to basket')
                                    }
                                />
                            </motion.div>
                        </div>
                    </motion.div>
                </Form>
            )}
        </Formik>
    )
}

export default PromoValidationForm
