import { createContext, useCallback, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import usePlaceQuery from '../hooks/usePlaceQuery'

export const PlaceContext = createContext()

function PlaceContextProvider({ children }) {
    const queryClient = useQueryClient()
    const [id, setId] = useState(undefined)
    const { isLoading, isError, place } = usePlaceQuery(id)

    const fetchPlaceById = useCallback(
        async (id) => {
            if (place?.id !== id) {
                await queryClient.invalidateQueries({ queryKey: ['place'] })
                setId(id)
            }
        },
        [place?.id, queryClient]
    )

    const setPlace = ({ id }) => fetchPlaceById(id)

    return (
        <PlaceContext.Provider value={{ isLoading, fetchPlaceById, place, setPlace, isError }}>
            {children}
        </PlaceContext.Provider>
    )
}

export default PlaceContextProvider
