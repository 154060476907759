/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useState } from 'react'
import PromotionRegistrationForm from 'src/components/02_molecules/PromotionRegistrationForms/PromotionRegistrationForm'
import PromoValidationForm from 'src/components/forms/PromoValidationForm'
import Promotion from 'src/structures/Classes/Promotion'

import './promotionregistrationfrom.scss'

interface IPromotionRegistrationOtpProperties {
    promotion: Promotion
    defaultPromoProductId: number | string
    onVerifiedRegistration: () => undefined
    onRegistration: (chosenProducts: any) => undefined
    isProductsMultiSelect?: boolean
}

const PromotionRegistrationOtp: FC<IPromotionRegistrationOtpProperties> = ({
    promotion,
    defaultPromoProductId,
    isProductsMultiSelect = false,
    onVerifiedRegistration,
    onRegistration,
}) => {
    const [otpGenerated, setOtpGenerated] = useState(false)

    const onSuccessfulOTPGenerate: any = useCallback((chosenProducts: any) => {
        setOtpGenerated(true)
        onRegistration(chosenProducts)
    }, [])

    if (otpGenerated) {
        return (
            <div className='otp-form'>
                <PromoValidationForm
                    onSuccessfulVerify={onVerifiedRegistration}
                    color={promotion.color}
                />
            </div>
        )
    }
    return (
        <div className='otp-form'>
            <PromotionRegistrationForm
                id={promotion.id}
                onSuccessfulGenerate={onSuccessfulOTPGenerate}
                color={promotion.color}
                promoProducts={promotion.products}
                initialPromoProductValue={defaultPromoProductId}
                multiSelect={isProductsMultiSelect}
                multiMax={
                    promotion.rules?.[0]?.config?.requiresIdenticalProducts === true
                        ? 1
                        : promotion.rules?.[0]?.config?.n ?? 1
                }
            />
        </div>
    )
}

export default PromotionRegistrationOtp
