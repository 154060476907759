import classNames from 'classnames'

import { sanitizeHtml } from '../../../constants/helpers'

import InputFeedback from './InputFeedback'

function Checkbox({
    field,
    form: { errors, touched },
    id,
    label,
    overrideLabel,
    className,
    disabled = false,
    ...props
}) {
    const classes = classNames(
        'c-checkbox--default',
        {
            'has-error': !!errors[field.name] && touched[field.name],
            'u-disabled': disabled,
        },
        className
    )

    return (
        <div className={classes}>
            <input
                id={id}
                type='checkbox'
                className={classNames('checkbox')}
                disabled={disabled}
                {...field}
                {...props}
            />

            {overrideLabel ? (
                <label
                    htmlFor={id}
                    style={{ paddingRight: 24 }}>
                    {overrideLabel}
                </label>
            ) : (
                <label
                    style={{ paddingRight: 24 }}
                    htmlFor={id}
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(label, { ALLOWED_ATTR: ['target', 'rel', 'href'] }),
                    }}
                />
            )}
            {touched[field.name] && <InputFeedback error={errors[field.name]} />}
        </div>
    )
}

export default Checkbox
