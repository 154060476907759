import { useContext } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'

import { ThemeContext } from '../../context/ThemeContextProvider'
import HeroType from '../../structures/Enums/HeroType.enum.ts'

import './header.scss'

function HeaderScrollBackground() {
    const { scrollY } = useScroll()
    const { theme } = useContext(ThemeContext)

    let scrollTravel = 100

    if (theme.heroStyle === HeroType.HERO_TYPE_MINIMAL || theme.heroStyle === HeroType.HERO_TYPE_NO_OVERLAY) {
        scrollTravel = 0
    }

    const opacity = useTransform(scrollY, [0, scrollTravel], [0, 1])

    return (
        <motion.div
            style={{ opacity }}
            className='c-header--scroll-bg'
        />
    )
}

export default HeaderScrollBackground
