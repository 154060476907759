import { FC, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { FaCircleCheck } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'
import Confetti from 'src/components/01_atoms/Confetti/Confetti'
import Modal from 'src/components/01_atoms/Modal/Modal'
import Picture from 'src/components/01_atoms/Picture/Picture'
import PromotionRegistrationOtp from 'src/components/02_molecules/PromotionRegistrationForms/PromotionRegistrationOtp'
import { UserContext } from 'src/context/UserAndTokenContextProvider'
import useTimeout from 'src/hooks/useTimeout'
import { closeModal } from 'src/redux/actions/actionBuilders'
import Promotion from 'src/structures/Classes/Promotion'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IModalDataProperties from 'src/structures/Interfaces/IModalDataProperties'

import { BRAND_TADA } from '../../../../structures/Enums/Brands.enum'
import PoweredBy from '../../../PoweredBy/PoweredBy'
import PromotionProductSelectForm, {
    ISelectedPromotionProduct,
} from '../../PromotionProductSelectForm/PromotionProductSelectForm'

import './promotionmodal.scss'

const PromotionModal: FC<IModalDataProperties> = ({ data }) => {
    const { t } = useTranslation()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { user } = useContext(UserContext) as any
    const dispatch = useDispatch()
    const promotion: Promotion = data.promo

    const isProductsMultiSelect =
        typeof promotion.rules?.[0]?.config.requiresIdenticalProducts === 'boolean'
            ? promotion.rules?.[0]?.config.requiresIdenticalProducts === false
            : false

    const [promoClaimSuccess, setPromoClaimSuccess] = useState(false)
    const [chosenPromoProducts, setChosenPromoProducts] = useState<Array<ISelectedPromotionProduct> | undefined>()

    const handleClose = useCallback(() => {
        data?.onDeny?.()
        dispatch(closeModal())
    }, [dispatch, data])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleRegistrationSuccess: any = useCallback((chosenProducts: any) => {
        setChosenPromoProducts(chosenProducts)
    }, [])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleVerificationSuccess: any = useCallback(() => {
        setPromoClaimSuccess(true)
    }, [user])

    useTimeout(() => data?.onSuccess?.(chosenPromoProducts), promoClaimSuccess ? 1800 : null)

    if (isNotNullOrUndefined(promotion)) {
        return (
            <Modal
                containerClassName='promotionmodal-container'
                contentClassName='promotionmodal-content'>
                <div className='inner'>
                    <div className='promotionmodal-close'>
                        <button
                            aria-label={t('general.button.close.label')}
                            onClick={handleClose}
                            className='c-button-close-modal-small'>
                            <FaTimes />
                        </button>
                    </div>
                    <div className='promotionmodal-header'>
                        {promotion.isTaDa ? (
                            <div className='promotionmodal-brand'>
                                <PoweredBy
                                    brandId={BRAND_TADA.identifier}
                                    isStaticPositioned
                                />
                            </div>
                        ) : null}
                        <div className='promotionmodal-header-image'>
                            {isNotNullOrUndefined(promotion.coverImage) ||
                            isNotNullOrUndefined(promotion.bannerImage) ? (
                                <Picture src={promotion.coverImage ?? promotion.bannerImage} />
                            ) : null}
                        </div>
                        <div className='promotionmodal-header-content'>
                            <h3
                                className='c-modal-promo-promoname'
                                style={
                                    isNotNullOrUndefined(promotion.color)
                                        ? {
                                              color: `#${String(promotion.color).replace('#', '')}`,
                                          }
                                        : {}
                                }>
                                {promotion.name}
                            </h3>
                        </div>
                    </div>

                    <div className='promotionmodal-forms'>
                        {promoClaimSuccess ? (
                            <div className='promotionmodal-forms-inner has-success'>
                                <FaCircleCheck className='check' />
                            </div>
                        ) : (
                            <div className='promotionmodal-forms-inner'>
                                {promotion.promotionSource.verification_type === 'otp' &&
                                isNotNullOrUndefined(user?.phoneVerified) ? (
                                    <PromotionProductSelectForm
                                        id={promotion.id}
                                        color={promotion.color}
                                        promoProducts={promotion.products}
                                        initialPromoProductValue={data.defaultPromoProductId}
                                        multiSelect={isProductsMultiSelect}
                                        multiMax={
                                            promotion.rules?.[0]?.config?.requiresIdenticalProducts === true
                                                ? 1
                                                : promotion.rules?.[0]?.config?.n ?? 1
                                        }
                                        onSubmit={(products: Array<ISelectedPromotionProduct>) => {
                                            setChosenPromoProducts(products ?? undefined)
                                            setPromoClaimSuccess(true)
                                        }}
                                    />
                                ) : (
                                    <PromotionRegistrationOtp
                                        promotion={promotion}
                                        onRegistration={handleRegistrationSuccess}
                                        onVerifiedRegistration={handleVerificationSuccess}
                                        defaultPromoProductId={data.defaultPromoProductId}
                                        isProductsMultiSelect
                                    />
                                )}
                                <button
                                    disabled={promoClaimSuccess}
                                    onClick={handleClose}
                                    className='promotionmodal-deny-button'>
                                    {t('general.button.close.label')}
                                </button>
                            </div>
                        )}
                        {promoClaimSuccess ? (
                            <div className='success-explosion'>
                                <Confetti y={1} />
                            </div>
                        ) : null}
                    </div>
                    {promotion.isTaDa ? <p className='promotionmodal-legal'>{t('legal.alcohol.plus18')}</p> : null}
                </div>
            </Modal>
        )
    }
    return null
}

export default PromotionModal
